import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../lib/supabase';
import { toast } from 'sonner';
import type { CompanyFormData } from '../lib/validations';

export function useCompanyInfo() {
  const queryClient = useQueryClient();

  const companyInfo = useQuery({
    queryKey: ['company-info'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('company_info')
        .select('*')
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
  });

  const updateCompanyInfo = useMutation({
    mutationFn: async (info: Partial<CompanyFormData>) => {
      const { error } = await supabase
        .from('company_info')
        .upsert({
          id: companyInfo.data?.id,
          ...info,
          updated_at: new Date().toISOString(),
        });

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company-info'] });
      toast.success('Company information updated successfully');
    },
    onError: (error) => {
      toast.error('Failed to update company information');
      console.error('Error updating company info:', error);
    },
  });

  const uploadLogo = useMutation({
    mutationFn: async (file: File) => {
      const fileExt = file.name.split('.').pop();
      const fileName = `logo.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('company-assets')
        .upload(fileName, file, { upsert: true });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('company-assets')
        .getPublicUrl(fileName);

      await updateCompanyInfo.mutateAsync({ logo_url: publicUrl });
      return publicUrl;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company-info'] });
      toast.success('Logo uploaded successfully');
    },
    onError: (error) => {
      toast.error('Failed to upload logo');
      console.error('Error uploading logo:', error);
    },
  });

  return {
    companyInfo,
    isLoading: companyInfo.isLoading,
    error: companyInfo.error,
    updateCompanyInfo,
    uploadLogo,
  };
}