import { z } from 'zod';

export const inspectionItemSchema = z.object({
  item_type: z.string().min(1, 'Item type is required'),
  floor: z.string().optional(),
  room: z.string().optional(),
  equipment_type: z.string().min(1, 'Equipment type is required'),
  status: z.enum(['pass', 'fail', 'no-access']),
  notes: z.string().optional(),
});

export const inspectionSchema = z.object({
  client_id: z.string().min(1, 'Client is required'),
  inspection_date: z.string().min(1, 'Inspection date is required'),
  location: z.string().min(1, 'Location is required'),
  inspector: z.string().min(1, 'Inspector is required'),
  status: z.enum(['scheduled', 'completed', 'failed']),
  notes: z.string().optional(),
  cover_page: z.boolean().default(true),
  items: z.array(inspectionItemSchema).min(1, 'At least one inspection item is required'),
});

export const clientSchema = z.object({
  name: z.string().min(1, 'Company name is required'),
  point_of_contact: z.string().optional(),
  inspection_types: z.array(z.string()).default([]),
  frequency: z.string().optional(),
  phone: z.string().optional(),
  street_address: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip_code: z.string().optional(),
  email: z.string().email('Invalid email address').optional().nullable(),
  notes: z.string().optional(),
  contract_start: z.string().optional(),
  contract_end: z.string().optional(),
  contract_amount: z.union([
    z.number(),
    z.string().transform((val) => {
      if (!val) return null;
      const num = Number(val);
      return isNaN(num) ? null : num;
    }),
    z.null()
  ]).optional(),
});

export const userSchema = z.object({
  email: z.string().email('Invalid email address'),
  role: z.enum(['admin', 'tech']),
  phone_number: z.string().optional(),
  notify_renewals: z.boolean(),
  notify_inspections: z.boolean(),
});

export const companySchema = z.object({
  name: z.string().optional(),
  address: z.string().optional(),
  phone: z.string().optional(),
  email: z.string().optional(),
  website: z.string().optional(),
});